import styled from 'styled-components';
import { theme } from '../theme/colors';
import '@fontsource/sora';

export const NumberBlock = styled.h2`
	background-color: ${theme.colors.primary};
	padding: 1rem;
	border-radius: 12px;
	font-size: 3rem;
	width: 6rem;
	height: 6rem;
	min-width: 6rem;
	min-height: 6rem;
	text-align: center;
	line-height: 6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${theme.colors.primaryText};
	font-family: 'Sora', sans-serif;
	margin-right: 2rem;

	@media (max-width: 767px) {
		width: 4rem;
		height: 4rem;
		min-width: 4rem;
		min-height: 4rem;
		line-height: 4rem;
		font-size: 1.5rem;
	}
`;
