import * as React from 'react';
import { BigTitle } from '../components/BigTitle';
import { LinkButton } from '../components/LinkButton';
import { CenteredDiv } from '../components/Layout/CenteredDiv';
import { FlexRow } from '../components/Layout/FlexRow';
import Footer from '../components/Footer';
import Logo from '../components/Logo';
import { NumberBlock } from '../components/NumberBlock';
import { Subtitle } from '../components/Subtitle';
import { Text } from '../components/Text';
import { TextBlock } from '../components/TextBlock';
import { Title } from '../components/Title';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/Layout';
import '../index.css';
import styled from 'styled-components';
import PhoneMock from '../images/phone_mockups.png';

const MainContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 4rem;
`;

const Image = styled.img`
	max-width: 60rem;

	@media (max-width: 767px) {
		max-width: 100vw;
	}
`;

const ImageContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 3rem;
	flex: 1;
	min-width: 10rem;

	@media (max-width: 767px) {
		max-width: 100vw;
	}
`;
const IndexPage = () => {
	return (
		<Layout>
			<Helmet>
				<meta charSet='utf-8' />
				<title>
					StudyFlow | A Learning Experience Designed for ADHD
				</title>
			</Helmet>
			<CenteredDiv style={{ minHeight: '100vh' }}>
				<Logo />
				<BigTitle
					style={{
						marginTop: '8rem',
						maxWidth: '45rem',
						textAlign: 'center',
					}}>
					Learning Experience Designed for ADHD. TikTok-like
					Addiction.
				</BigTitle>
				<Subtitle style={{ maxWidth: '40rem' }}>
					Our mobile-first learning experience combines proven
					learning strategies and short videos into an easy-to-use
					app. We've created the most effective way for students with
					ADHD to excel where school has failed them.
				</Subtitle>
				<LinkButton style={{ padding: '1rem 3rem' }} to='/contact'>
					Start learning for free
				</LinkButton>
				<ImageContainer>
					<Image src={PhoneMock} />
				</ImageContainer>
				<MainContent>
					<CenteredDiv>
						<FlexRow>
							<NumberBlock>1.</NumberBlock>
							<TextBlock>
								<Title>
									Takes just 20 minutes per day, from your
									phone
								</Title>
								<Text>
									We've designed our experience to cater to
									your attention span. Plus, with our
									mobile-first approach you can study from
									anywhere!
								</Text>
							</TextBlock>
						</FlexRow>
						<FlexRow>
							<NumberBlock>2.</NumberBlock>
							<TextBlock>
								<Title>
									Learn with short videos (less than 2 minutes
									each)
								</Title>
								<Text>
									With StudyFlow, each concept is broken up
									into short videos (never longer than 2
									minutes) and followed up with an engaging
									question after each. Learning is now as
									simple as scrolling through TikTok!
								</Text>
							</TextBlock>
						</FlexRow>
						<FlexRow>
							<NumberBlock>3.</NumberBlock>
							<TextBlock>
								<Title>Never worry about what to study</Title>
								<Text>
									Our linear feed technology manages
									everything for you, so you don't worry about
									what to study. It also utilizes proven
									learning strategies such as Spacing and
									Interleaving to improve long-term retention.
								</Text>
							</TextBlock>
						</FlexRow>
						<LinkButton
							style={{ padding: '1rem 3rem' }}
							to='/contact'>
							Try free
						</LinkButton>
					</CenteredDiv>
				</MainContent>
			</CenteredDiv>
			<Footer />
		</Layout>
	);
};

export default IndexPage;
