import styled from 'styled-components';
import { theme } from '../theme/colors';
import '@fontsource/sora';
import { Link } from 'gatsby';

export const LinkButton = styled(Link)`
	border: none;
	background: none;
	border-radius: 6px;
	background-color: #ff325c;
	color: ${theme.colors.primaryText};
	text-decoration: none;
	font-family: 'Sora', sans-serif;
	font-weight: 600;
	font-size: 1.1rem;
	padding: 0.75rem 2rem;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	transition: all 200ms ease;
	margin-bottom: 2rem;

	&:hover {
		background-color: #ff325b9a;
	}
`;
