import styled from 'styled-components';
import '@fontsource/sora/600.css';
import { theme } from '../theme/colors';

export const Title = styled.h1`
	font-size: 1.75rem;
	font-family: 'Sora', sans-serif;
	margin: 0;
	margin-bottom: 1rem;
	line-height: 2.25rem;
	color: ${theme.colors.primaryText};

	@media (max-width: 760px) {
		font-size: 1.5rem;
	}
`;
