import styled from 'styled-components';

export const TextBlock = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	max-width: 40rem;

	@media (max-width: 760px) {
		max-width: 100%;
	}
`;
