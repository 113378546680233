import styled from 'styled-components';
import '@fontsource/sora';
import { theme } from '../theme/colors';

export const Subtitle = styled.h4`
	font-size: 1.25rem;
	font-family: 'Sora', sans-serif;
	font-weight: 500;
	margin: 0;
	margin-bottom: 2rem;
	line-height: 2rem;
	color: ${theme.colors.secondaryText};

	@media (max-width: 760px) {
		font-size: 1rem;
	}
`;
